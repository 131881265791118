<template>
  <div ref="wordCloud"></div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";
import { nextTick } from "vue";

export default {
  name: "echarts-wordCloud",
  data() {
    return {
      colors: [
        "#1f77b4",
        "#ff7f0e",
        "#efd566",
        "#ef824a",
        "#ba909d",
        "#e1757c",
        "#a87272",
        "#ebcdbc",
        "#bcd094",
        "#d3baed",
        "#c4efed",
        "#a05f71",
        "#bb7f68",
        "#9f5f71",
        "#a77370",
        "#c4efcf",
        "#a05f71",
        "#1f77b4",
        "#ff7f0e",
        "#efd566",
        "#ef824a",
        "#ba909d",
        "#e1757c",
        "#a87272",
        "#ebcdbc",
        "#bcd094",
        "#d3baed",
        "#c4efed",
        "#a05f71",
        "#bb7f68",
        "#9f5f71",
        "#a77370",
        "#c4efcf",
        "#a05f71",
      ],
      colorIndex: 0,
      echartsInstance: null,
    };
  },
  props: {
    words: {
      type: Array,
      default() {
        return [
          { name: "钱", value: 505 },
        ];
      },
    },
    isProuctHotWord: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    nextTick(() => {
      this.echartsInstance = echarts.init(this.$refs.wordCloud);
      this.creatWordCloud();
      if (this.isProuctHotWord) {
        this.echartsInstance.on("click", (params) => {
          this.$emit("clickHotWords", params.name);
        });
      }
    });
  },
  beforeUnmount() {
    this.echartsInstance.dispose();
  },
  methods: {
    creatWordCloud() {
      this.echartsInstance.setOption({
        series: [
          {
            type: "wordCloud",
            // 网格大小，各项之间间距
            gridSize: 18,
            // 形状 circle 圆，cardioid  心， diamond 菱形，
            // triangle-forward 、triangle 三角，star五角星
            shape: "circle",
            // 字体大小范围
            sizeRange: [14, 70],
            // 文字旋转角度范围
            rotationRange: [0, 0],
            // 旋转步值
            rotationStep: 90,
            // 自定义图形
            // maskImage: maskImage,
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            // 画布宽
            width: "95%",
            // 画布高
            height: "70%",
            // 是否渲染超出画布的文字
            drawOutOfBound: false,
            textStyle: {
              color: () => {
                this.colorIndex++;
                return this.colors[this.colorIndex % 16];
              },
            },
            emphasis: this.emphasis,
            data: this.words,
          },
        ],
      });
    },
  },
  computed: {
    emphasis() {
      if (this.isProuctHotWord) {
        return {
          focus: "self",
          textStyle: {
            shadowBlur: 10,
            shadowColor: "#2ac",
          },
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    words() {
      this.creatWordCloud();
    },
  },
};
</script>

<style scoped lang="scss"></style>
